<template>
  <div>
    <el-form v-model="filter" size="small" label-width="70px">
      <el-row :gutter="10" type="flex" justify="start" style="flex-wrap: wrap; flex-direction: row">
        <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
          <el-form-item label="Date" label-width="60px" class="mb5">
            <el-date-picker
              v-model="startToEndDate"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              align="right"
              unlink-panels
              :clearable="false"
              range-separator="-"
              start-placeholder="Start Date"
              end-placeholder="End Date"
              :picker-options="pickerOptions"
              size="mini"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
          <el-form-item label="Package" label-width="120px" class="mb5">
            <el-row>
              <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                <el-input
                  v-model="filter.pkgs"
                  placeholder="eg. com.whatsapp,com.nutclean"
                ></el-input>
              </el-col>
              <!--              <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                <el-checkbox class="color-white" v-model="filterCheckedStatus" label="pkg"
                >.
                </el-checkbox>
              </el-col>-->
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
          <el-form-item label="pid(s)" label-width="80px" class="mb5">
            <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
              <el-select
                v-model="filter.pids"
                multiple
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
                clearable
              >
                <el-option v-for="item in pidList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-col>
            <!--            <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
              <el-checkbox v-model="filterCheckedStatus" class="color-white" label="pid"
              >.
              </el-checkbox>
            </el-col>-->
          </el-form-item>
        </el-col>
        <el-col :span="24" align="right">
          <el-form-item label-width="0">
            <el-button type="primary" icon="el-icon-download" @click="downloadFie"
              >download
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-search"
              :loading="loading.list"
              @click="getList(1)"
              >Search
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      v-loading="loading.list"
      ref="tableRef"
      class="w100"
      height="70vmin"
      stripe
      border
      :data="list"
      size="mini"
      highlight-current-row
      @sort-change="sortChange"
    >
      <el-table-column label="pid" prop="pid" align="center"></el-table-column>
      <el-table-column
        label="receivedEmail"
        prop="receivedEmail"
        align="center"
        min-width="90"
      ></el-table-column>
      <el-table-column label="pkg" prop="pkg" align="center" min-width="90"></el-table-column>
      <el-table-column label="pm" prop="remark" align="center" min-width="160"></el-table-column>
      <el-table-column
        label="receivedDate"
        prop="receivedDate"
        align="center"
        show-overflow-tooltip
        min-width="180"
      ></el-table-column>
      <el-table-column
        label="clicks"
        prop="clicks"
        align="center"
        min-width="100"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        label="installs"
        prop="installs"
        align="center"
        min-width="100"
        sortable="custom"
      ></el-table-column>
      <el-table-column label="cvr" prop="cvr" align="center" min-width="100"></el-table-column>
      <el-table-column
        label="rejectRate"
        prop="rejectRate"
        align="center"
        min-width="100"
      ></el-table-column>
    </el-table>
    <pagination
      :pageTotal="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageParam.pageSize"
      :currentPage="pageParam.page"
    ></pagination>
  </div>
</template>

<script>
  import { getAlterEmailReport } from '@/api/report';
  import { myBlockMixin } from '@/mixins/blockmixins.js';
  import Pagination from '@/components/pagination';
  import { getPidsList } from 'api/product/rankSearch';
  import dateUtils from '@/utils/dateutils.js';
  import { filterObject } from 'utils/utils';
  import qs from 'querystring';

  export default {
    name: 'AlterEmailReport',
    mixins: [myBlockMixin],
    components: {
      Pagination,
    },
    data() {
      return {
        option: {
          pidList: [],
          appIdList: [],
        },
        fullWidth: document.documentElement.clientWidth,
        list: [],
        loading: {
          list: false,
          dialog: false,
        },
        postData: {},
        pageParam: {
          page: 1,
          pageSize: 20,
          total: 0,
        },
        total: 0,
        appsList: [],
        allPidAppid: [],
        pidList: [],
        startToEndDate: '',
        filter: {
          columns: [],
        },
        // 筛选参数状态
        filterCheckedStatus: [],
      };
    },
    mounted() {
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(start);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);
      this.getPids();
      this.getList();
    },
    methods: {
      extracted: function() {
        const param = {
          ...this.pageParam,
        };

        if (this.filter.pkgs) {
          param.pkgs = this.filter.pkgs;
        }

        if (this.filter.pids) {
          param.pids = this.filter.pids.toString();
        }
        param.columns = this.filter.columns.concat(this.filterCheckedStatus).toString();
        if (this.filter.sorting) {
          param.sorting = this.filter.sorting;
        }
        if (this.filter.sortType) {
          param.sortType = this.filter.sortType;
        }
        if (this.startToEndDate !== null && this.startToEndDate.length === 2) {
          let startTime = this.startToEndDate[0];
          let endTime = this.startToEndDate[1];
          param.fromDate = startTime + ' 00:00:00';
          param.toDate = endTime + ' 23:59:59';
        }
        return param;
      },
      // 获取数据列表
      getList(curPage) {
        if (curPage) {
          this.pageParam.page = curPage;
        }
        const param = this.extracted();
        getAlterEmailReport(param)
          .then((res) => {
            this.loading.list = true;
            this.total = res.total;
            this.list = res.result;
            this.loading.list = false;
          })
          .catch(() => {
            this.loading.list = false;
          });
      },
      getPids() {
        getPidsList().then((res) => {
          this.pidList = res.result;
        });
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.getList();
      },
      // 下载excel表格
      downloadFie() {
        this.$confirm('最多下载10万条数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            let param = this.extracted();
            param = filterObject(param);
            let strParam = qs.stringify(param);
            let url = '/api/adv/alterEmailDownload?' + strParam;
            console.log(url);
            window.open(url, '_blank');
          })
          .catch((error) => {
            this.$message({
              type: 'info',
              message: error.message,
            });
          });
      },
      // 排序监听
      sortChange(column) {
        if (column.prop !== null && column.order !== null) {
          this.filter.sorting = column.prop;
          this.filter.sortType = column.order === 'ascending' ? 'asc' : 'desc';
        }
        this.handleCurrentChange(1);
      },
    },
  };
</script>

<style scoped lang="scss">
  .popover_icon {
    width: 60px;
  }

  .form_img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  @media screen and (min-width: 768px) {
    .popover_icon {
      width: 140px;
    }
  }

  @media screen and (max-width: 768px) {
    .appsflyer_account_dialog {
      ::v-deep .el-form-item__label {
        padding-bottom: 0;
      }

      ::v-deep .el-dialog__body {
        padding-top: 10px;
      }
    }
  }
</style>
