<template>
  <div class="commonReport">
    <div class="bgf0 p10">
      <div class="p10 bgff mainBox">
        <el-tabs v-model="activeName" class="bgff">
          <el-tab-pane label="AppsFlyer Account" name="account">
            <appsflyer-account></appsflyer-account>
          </el-tab-pane>
          <el-tab-pane label="AppsFlyer Agency" name="agency">
            <appsflyer-agency></appsflyer-agency>
          </el-tab-pane>
          <el-tab-pane label="AppsFlyer pa" name="pa">
            <appsflyer-pa-report></appsflyer-pa-report>
          </el-tab-pane>
          <el-tab-pane label="AlterEmailReport" name="alter">
            <alter-email-report></alter-email-report>
          </el-tab-pane>
          <el-tab-pane label="AppsFlyer Report" name="report">
            <el-form
              v-model="commonReport"
              size="small"
              class="form_width"
              label-width="90px"
              :label-position="fullWidth < 520 ? 'top' : 'right'"
            >
              <el-row :gutter="20">
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                  <el-form-item label="PID">
                    <div class="can_checked">
                      <el-select
                        v-model="commonReport.pids"
                        filterable
                        multiple
                        clearable
                        collapse-tags
                        placeholder="Please select"
                        class="w100"
                      >
                        <el-option v-for="item in pidList" :key="item" :label="item" :value="item">
                        </el-option>
                      </el-select>
                      <el-checkbox class="checked_style" v-model="pidChecked"></el-checkbox>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                  <el-form-item label="App ID">
                    <div class="can_checked">
                      <group-select
                        v-model="commonReport.pkgs"
                        :data-source="appIdList"
                        multiple
                        reserve-keyword
                        collapse-tags
                        clearable
                        filterable
                        placeholder="Please select"
                        class="w100"
                      />
                      <el-checkbox class="checked_style" v-model="appIdChecked"></el-checkbox>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                  <el-form-item label="Date">
                    <div class="can_checked">
                      <el-date-picker
                        v-model="startToEndDate"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        class="w100"
                        align="right"
                        unlink-panels
                        clearable
                        range-separator="-"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        :picker-options="pickerOptions"
                      ></el-date-picker>
                      <el-checkbox class="checked_style" v-model="dateChecked"></el-checkbox>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="24" align="right">
                  <el-form-item label-width="0">
                    <el-button
                      type="primary"
                      size="small"
                      icon="el-icon-search"
                      :loading="loading.search"
                      @click="searchListClick(1)"
                      >Search</el-button
                    >
                    <el-button
                      icon="el-icon-download"
                      type="warning"
                      size="small"
                      @click="exportExcelFileClick"
                      >Download</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <el-card shadow="hover">
              <el-table
                v-loading="listLoading"
                ref="tableRef"
                style="width: 100%"
                height="70vmin"
                stripe
                border
                size="mini"
                :data="hotReportList"
                highlight-current-row
                @sort-change="sortChange"
              >
                <el-table-column
                  label="Date"
                  min-width="120"
                  prop="report_day"
                  align="center"
                  :fixed="fullWidth <= 768 ? false : 'left'"
                  v-if="dateChecked || monthChecked || hourChecked"
                  key="report_day"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="PID"
                  min-width="120"
                  align="center"
                  :fixed="fullWidth <= 768 ? false : 'left'"
                  v-if="pidChecked"
                  key="pid"
                  prop="pid"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    {{ scope.row.pid || '-' }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="App ID"
                  min-width="140"
                  align="center"
                  prop="app_id"
                  :fixed="fullWidth <= 768 ? false : 'left'"
                  v-if="appIdChecked"
                  key="app_id"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    {{ scope.row.app_id || '-' }}
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="campaignChecked"
                  label="campaign"
                  prop="campaign"
                  align="center"
                  key="campaign"
                ></el-table-column>
                <el-table-column
                  label="installs"
                  prop="installs"
                  align="center"
                  key="installs"
                ></el-table-column>
                <el-table-column
                  label="impressions"
                  prop="impressions"
                  align="center"
                  key="impressions"
                ></el-table-column>
                <el-table-column
                  label="AF Clicks"
                  prop="clicks"
                  align="center"
                  min-width="100"
                  key="afClicks"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  label="cvr"
                  prop="conv_rate"
                  align="center"
                  key="conv_rate"
                ></el-table-column>
                <!-- <el-table-column
                  label="Total"
                  prop="installs"
                  align="center"
                  key="installs"
                ></el-table-column>
                <el-table-column
                  label="Installs"
                  prop="installsUa"
                  align="center"
                  key="installsUa"
                ></el-table-column>
                <el-table-column
                  label="Re-attributions"
                  prop="installsReattr"
                  align="center"
                  min-width="120"
                  key="installsReattr"
                ></el-table-column>
                <el-table-column
                  label="Re-engagements"
                  prop="installsRetarget"
                  align="center"
                  min-width="130"
                  key="installsRetarget"
                ></el-table-column>
                <el-table-column
                  label="Impressions"
                  prop="impressions"
                  align="center"
                  min-width="100"
                  key="impressions"
                ></el-table-column> -->
                <!--                <el-table-column
                  v-if="!campaignChecked"
                  label="Bid Clicks"
                  prop="bid_clicks"
                  align="center"
                  min-width="130"
                  key="bid_clicks"
                  sortable="custom"
                >
                </el-table-column>-->
                <el-table-column
                  label="diff Ratio"
                  prop="diffRatio"
                  align="center"
                  min-width="130"
                  key="diffRatio"
                >
                  <template slot-scope="{ row }">
                    {{ row.diffRatio.toFixed(2) }}
                  </template>
                </el-table-column>
              </el-table>
              <div class="align-c">
                <pagination
                  class="block pagePanel"
                  :pageTotal="total"
                  @handleSizeChange="handleSizeChange"
                  @handleCurrentChange="handleCurrentChange"
                  :pageSize="pageParam.pageSize"
                  :currentPage="pageParam.page"
                ></pagination>
              </div>
            </el-card>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
  import { getAppsFlyerReport, getPidAppIdData } from 'api/report';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';
  import { cloneDeep } from 'lodash-es';
  import dateUtils from '@/utils/dateutils.js';
  import qs from 'querystring';
  import { myMixin } from '@/mixins/mixins.js';
  import optionData from '@/assets/js/optionData';
  import { filterObject } from 'utils/utils';
  import AppsflyerAccount from './AppsflyerAccount.vue';
  import AppsflyerPaReport from './AppsflyerPaReport.vue';
  import AppsflyerAgency from './AppsflyerAgency.vue';
  import AlterEmailReport from './AlterEmailReport.vue';

  export default {
    mixins: [myMixin],
    components: {
      AlterEmailReport,
      Pagination,
      GroupSelect,
      AppsflyerAccount,
      AppsflyerPaReport,
      AppsflyerAgency,
    },
    data() {
      return {
        pageParam: {
          page: 1,
          pageSize: 20,
        },
        activeName: 'account',
        dataParam: null,
        fullWidth: document.documentElement.clientWidth,
        pidChecked: true,
        appIdChecked: true,
        monthChecked: false,
        dateChecked: true,
        campaignChecked: false,
        hourChecked: false,
        startToEndDate: '',
        listLoading: false,
        total: null,
        summariesMap: {},
        hotReportList: [],
        timezoneOption: optionData.timezoneOption,
        platformOption: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'ANDROID',
            value: 'ANDROID',
          },
          {
            label: 'IOS',
            value: 'IOS',
          },
        ],
        commonReport: {
          pid: null,
          pkgs: null,
          columns: [],
          fromDate: null,
          toDate: null,
          timezone: null,
        },
        historyDeductList: [],
        sourceIdArray: [],
        affiliateIdArray: [],
        subRule: [],
        loading: {
          search: false,
        },
        pidList: [],
        appIdList: [],
        pidAppidObject: {},
      };
    },
    mounted() {
      //默认选择 - 3600 * 1000 * 24
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime());
      end.setTime(end.getTime());
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);
      this.commonReport.timezone = '+00:00';
      this.getPidAppidList();
      this.searchListClick();
    },
    computed: {
      listenChange() {
        const {
          pidChecked,
          appIdChecked,
          dateChecked,
          monthChecked,
          hourChecked,
          campaignChecked,
        } = this;
        return {
          pidChecked,
          appIdChecked,
          dateChecked,
          monthChecked,
          hourChecked,
          campaignChecked,
        };
      },
    },
    watch: {
      listenChange: function() {
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        });
      },
    },
    filters: {
      formatDiffRatio(value) {
        // 将值保留两位小数
        return value.toFixed(2);
      },
    },
    methods: {
      searchListClick(curPage) {
        if (curPage) {
          this.pageParam.page = curPage;
        }
        let param = {
          ...this.commonReport,
          ...this.pageParam,
        };
        if (Array.isArray(param.pids) && param.pids.length > 0) {
          param.pids = param.pids.join(',');
        } else {
          delete param.pids;
        }
        if (Array.isArray(param.pkgs) && param.pkgs.length > 0) {
          param.pkgs = param.pkgs.join(',');
        } else {
          delete param.pkgs;
        }

        if (this.pidChecked == true && param.columns.indexOf('pid') == -1) {
          param.columns.push('pid');
        } else if (this.pidChecked == false && param.columns.indexOf('pid') !== -1) {
          const offerIdIndex = param.columns.findIndex((item) => {
            if (item == 'pid') {
              return true;
            }
          });
          param.columns.splice(offerIdIndex, 1);
        }

        if (this.appIdChecked == true && param.columns.indexOf('app_id') == -1) {
          param.columns.push('app_id');
        } else if (this.appIdChecked == false && param.columns.indexOf('app_id') !== -1) {
          const eventNameIndex = param.columns.findIndex((item) => {
            if (item == 'app_id') {
              return true;
            }
          });
          param.columns.splice(eventNameIndex, 1);
        }

        if (this.monthChecked == true && param.columns.indexOf('month') == -1) {
          param.columns.push('month');
        } else if (this.monthChecked == false && param.columns.indexOf('month') !== -1) {
          const monthIndex = param.columns.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          param.columns.splice(monthIndex, 1);
        }
        if (this.dateChecked == true && param.columns.indexOf('report_day') == -1) {
          param.columns.push('report_day');
        } else if (this.dateChecked == false && param.columns.indexOf('report_day') !== -1) {
          const dateIndex = param.columns.findIndex((item) => {
            if (item == 'report_day') {
              return true;
            }
          });
          param.columns.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && param.columns.indexOf('hour') == -1) {
          param.columns.push('hour');
        } else if (this.hourChecked == false && param.columns.indexOf('hour') !== -1) {
          const hourIndex = param.columns.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          param.columns.splice(hourIndex, 1);
        }

        if (this.campaignChecked === true && param.columns.indexOf('campaign') == -1) {
          param.columns.push('campaign');
        } else if (this.campaignChecked == false && param.columns.indexOf('campaign') !== -1) {
          const dateIndex = param.columns.findIndex((item) => {
            if (item == 'campaign') {
              return true;
            }
          });
          param.columns.splice(dateIndex, 1);
        }

        if (this.startToEndDate.length === 2) {
          let startDate = this.startToEndDate[0];
          let endDate = this.startToEndDate[1];
          param.fromDate = startDate;
          param.toDate = endDate;
        }
        this.pageParam.page = 1;

        const dataParam = cloneDeep(param);
        if (Array.isArray(dataParam.columns)) {
          dataParam.columns = dataParam.columns.join(',');
        }
        this.dataParam = dataParam;
        this.commonReportApi();
      },
      commonReportApi() {
        this.listLoading = true;
        this.loading.search = true;
        let param = {
          ...this.dataParam,
          ...this.pageParam,
        };
        param = filterObject(param);
        getAppsFlyerReport(param).then((response) => {
          this.listLoading = false;
          this.loading.search = false;
          if (response.code === 200) {
            const result = response.result || {};
            // this.sumInfo = res.result.pop();
            this.summariesMap = result.pop();
            this.hotReportList = result;
            this.total = response.total;
            if (this.summariesMap) {
              this.summariesMap.report_day = 'Total';
              this.hotReportList.push(this.summariesMap);
            }
            this.$refs.tableRef.doLayout();
          } else {
            this.hotReportList = [];
            this.summariesMap = {};
            this.total = null;
            this.loading.search = false;
            this.$message.error(response.message);
          }
        });
      },
      exportExcelFileClick() {
        let param = {
          ...this.dataParam,
          ...this.pageParam,
        };
        param = filterObject(param);
        let strParam = qs.stringify(param);
        let url = '/api/adv/appflyer/download?' + strParam;
        window.open(url, '_blank');
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.commonReportApi();
      },
      filterNull(val) {
        return val == null ? 0 : val;
      },
      getPidAppidList() {
        getPidAppIdData().then((response) => {
          let _this = this;
          this.pidList = [];
          this.appIdList = [];
          this.pidAppidObject = {};
          if (response.code === 200) {
            response.result.forEach((item, index) => {
              _this.pidAppidObject[item.pid] = item.appIds;
              _this.pidList.push(item.pid);
              const group1 = {
                id: index + 1,
                checked: false,
                label: item.pid,
                children: [],
              };
              item.appIds.forEach((item1) => {
                group1.children.push({
                  id: item1,
                  value: item1,
                  label: item1,
                });
              });
              _this.appIdList.push(group1);
            });
          }
        });
      },
      pageJump(row) {
        if (sessionStorage.getItem('systemClicks')) {
          sessionStorage.removeItem('systemClicks');
        }
        let params = encodeURIComponent(
          JSON.stringify({
            package: row.appId,
            pid: row.pid,
            timeZone: this.commonReport.timezone,
            fromDate: this.startToEndDate[0],
            toDate: this.startToEndDate[1],
            interval: {
              monthChecked: this.monthChecked,
              dateChecked: this.dateChecked,
              hourChecked: this.hourChecked,
            },
          })
        );
        sessionStorage.setItem('systemClicks', params);
        const { href } = this.$router.resolve({
          path: '/report/index',
          query: {
            sourcePath: 'AppsFlyerReport',
          },
        });
        window.open(href, '_blank');
      },
      // 排序监听
      sortChange(column) {
        if (column.prop !== null && column.order !== null) {
          this.dataParam.sorting = column.prop;
          this.dataParam.sortType = column.order === 'ascending' ? 'asc' : 'desc';
        }
        this.handleCurrentChange(1);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .can_checked {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .checked_style {
      width: 30px;
      margin-right: 0;
      padding-left: 15px;
      box-sizing: border-box;
    }
    .w100 {
      width: calc(100% - 30px) !important;
    }
  }
  .flex_start {
    justify-content: flex-start;
  }
  .el-form-item {
    height: 40px;
  }
  .to_link {
    cursor: pointer;
    color: #409eff;
  }

  @media screen and (min-width: 993px) {
    .form_width {
      ::v-deep .el-form-item {
        height: 30px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .form_width {
      ::v-deep .el-form-item {
        height: inherit;
      }
    }
  }
  @media screen and (max-width: 520px) {
    .mainBox {
      ::v-deep .form_width {
        .el-form-item__content,
        .el-form-item__label {
          padding-bottom: 0 !important;
        }
      }
    }
  }
</style>
